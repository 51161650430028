import {Avatar} from "./Avatar";

export class SpriterAvatar extends Avatar {

    private readonly _images: HTMLImageElement[];

    constructor(name: string, scale: number, images: HTMLImageElement[]) {
        super(name, scale)
        this._images = images;
    }

    get images(): HTMLImageElement[] {
        return this._images;
    }

}