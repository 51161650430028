import {GameResponse} from "../api/GameResponse";
import {SpriterAvatar} from "./SpriterAvatar";
import {StaticAvatar} from "./StaticAvatar";
import {SpriterAvatarResponse} from "../api/AvatarResponse";

export class Avatars {

    private readonly _spriterAvatars: Map<string, SpriterAvatar>;
    private readonly _staticAvatars: Map<string, StaticAvatar>;
    private readonly _dialogFrame: HTMLImageElement;
    private readonly _dialogNext: HTMLImageElement;
    private readonly _dialogClose: HTMLImageElement;
    private readonly _inventoryWeapons: HTMLImageElement;
    private readonly _inventoryItems: HTMLImageElement;
    private readonly _inventorySave: HTMLImageElement;
    private readonly _inventoryDoor: HTMLImageElement;

    constructor(
        spriterAvatars: Map<string, SpriterAvatar>,
        staticAvatars: Map<string, StaticAvatar>,
        dialogFrame: HTMLImageElement,
        dialogNext: HTMLImageElement,
        dialogClose: HTMLImageElement,
        inventoryWeapons: HTMLImageElement,
        inventoryItems: HTMLImageElement,
        inventorySave: HTMLImageElement,
        inventoryDoor: HTMLImageElement
    ) {
        this._spriterAvatars = spriterAvatars;
        this._staticAvatars = staticAvatars;
        this._dialogFrame = dialogFrame;
        this._dialogNext = dialogNext;
        this._dialogClose = dialogClose;
        this._inventoryWeapons = inventoryWeapons;
        this._inventoryItems = inventoryItems;
        this._inventorySave = inventorySave;
        this._inventoryDoor = inventoryDoor;
    }

    public static async fromResponse(gameResponse: GameResponse, heroesResponse: SpriterAvatarResponse[]): Promise<Avatars> {

        //prepare buffer
        const imagesBuffer = new Set<string>();
        const commonImages = gameResponse.images as any;
        Object.keys(commonImages).forEach(key => imagesBuffer.add(commonImages[key]));
        heroesResponse.map(avatar => avatar.images.forEach(image => imagesBuffer.add(image)));
        gameResponse.spriterAvatars.forEach(avatar => avatar.images.forEach(image => imagesBuffer.add(image)));
        gameResponse.staticAvatars.forEach(avatar => imagesBuffer.add(avatar.image));

        //load images
        const images = new Map<string, HTMLImageElement>();
        const promises = Array.from(imagesBuffer).map(imageJson => this.imagePromise(imageJson));
        let loadedImages = await Promise.all(promises);
        loadedImages.forEach(loadedImage => images.set(loadedImage.src, loadedImage.image));

        //spriter avatars
        const spriterAvatars = new Map<string, SpriterAvatar>();
        heroesResponse.concat(gameResponse.spriterAvatars).forEach(avatar => {
            spriterAvatars.set(avatar.id, new SpriterAvatar(avatar.name, avatar.scale, avatar.images.map(image => images.get(image)!)))
        });

        //static avatars
        const staticAvatars = new Map<string, StaticAvatar>();
        gameResponse.staticAvatars.forEach(avatar => {
            staticAvatars.set(avatar.id, new StaticAvatar(avatar.name, avatar.scale, images.get(avatar.image)!));
        });

        return new Avatars(
            spriterAvatars,
            staticAvatars,
            images.get(gameResponse.images.dialogFrame)!,
            images.get(gameResponse.images.dialogNext)!,
            images.get(gameResponse.images.dialogClose)!,
            images.get(gameResponse.images.inventoryWeapons)!,
            images.get(gameResponse.images.inventoryItems)!,
            images.get(gameResponse.images.inventorySave)!,
            images.get(gameResponse.images.inventoryDoor)!,
        );
    }

    private static imagePromise(imageSrc: string): Promise<{ src: string, image: HTMLImageElement }> {
        return new Promise((resolve, reject) => {
            const newImage = new Image();
            newImage.src = imageSrc;
            newImage.onload = () => resolve({src: imageSrc, image: newImage});
            newImage.onerror = reject;
        });
    }

    getSpriterAvatar(id: string): SpriterAvatar {
        const avatar = this._spriterAvatars.get(id);
        if (avatar) return avatar;
        throw new Error("Unable to find avatar with id " + id);
    }

    getStaticAvatar(id: string): StaticAvatar {
        const avatar = this._staticAvatars.get(id);
        if (avatar) return avatar;
        throw new Error("Unable to find avatar with id " + id);
    }

    get dialogFrame(): HTMLImageElement {
        return this._dialogFrame;
    }

    get dialogNext(): HTMLImageElement {
        return this._dialogNext;
    }

    get dialogClose(): HTMLImageElement {
        return this._dialogClose;
    }

    get inventoryWeapons(): HTMLImageElement {
        return this._inventoryWeapons;
    }

    get inventoryItems(): HTMLImageElement {
        return this._inventoryItems;
    }

    get inventorySave(): HTMLImageElement {
        return this._inventorySave;
    }

    get inventoryDoor(): HTMLImageElement {
        return this._inventoryDoor;
    }

}