import React, {useContext} from "react";
import {SessionContext} from "./Session";
import {ItemData} from "../data/ItemData";
import {Position} from "../data/Position";
import {Direction} from "../data/Direction";
import Static from "./Static";

interface ItemProps {
    item: ItemData,
    acquireLock: () => boolean,
    walkHero: (position: Position, direction: Direction, onWalkFinished: () => void) => void
}

const Item = (props: ItemProps) => {

    const {addItem} = useContext(SessionContext)!;

    const {
        item,
        acquireLock,
        walkHero
    } = props;

    const onWalkFinish = () => {
        addItem(item.id);
    }

    const onClickHandler = () => {
        if (!acquireLock()) return;
        walkHero(item.getEngagingPosition(), item.getEngagingDirection(), onWalkFinish);
    }

    return <Static id={item.id}
                   position={item.position}
                   onClickHandler={onClickHandler}/>

}

export default Item;