import React, {useContext} from "react";
import "../styles/inventory.scss"
import {GameContext} from "./Game";
import {SessionContext} from "./Session";
import {CanvasContext} from "./Canvas";

const Inventory = () => {

    console.log("INVENTORY")

    const {closeFullScreen} = useContext(CanvasContext)!;
    const {itemIds} = useContext(SessionContext)!;
    const {avatars} = useContext(GameContext)!;

    const emptyWeapon = () => {
        return emptyDivWithImage(avatars.inventoryWeapons.src);
    }

    const emptyItem = () => {
        return emptyDivWithImage(avatars.inventoryItems.src);
    }

    const emptyDivWithImage = (image: string) => {
        return <div className={"Empty"} style={{backgroundImage: "url(" + image + ")"}}/>;
    }

    const divWithImage = (image: string) => {
        return <div style={{backgroundImage: "url(" + image + ")"}}/>;
    }

    const renderItem = (index: number) => {
        const itemId = itemIds[index];
        if (!itemId) return emptyItem();
        const image = avatars.getStaticAvatar(itemId).image
        return divWithImage(image.src);
    }

    return (
        <div className={"Inventory"}>
            <div className={"Grid Weapons"}>
                <div>{emptyWeapon()}</div>
                <div>{emptyWeapon()}</div>
            </div>
            <div className={"Grid Items"}>
                <div>{renderItem(0)}</div>
                <div>{renderItem(1)}</div>
                <div>{renderItem(2)}</div>
            </div>
            <div className={"Grid Buttons"}>
                <div>{divWithImage(avatars.inventorySave.src)}</div>
                <div onClick={closeFullScreen}>{divWithImage(avatars.inventoryDoor.src)}</div>
            </div>
        </div>
    )

}

export default Inventory;