import React, {forwardRef, useContext, useImperativeHandle, useRef, useState} from "react";
import TypingText, {TypingTextInterface} from "./TypingText";
import "../styles/dialogs.scss"
import {GameContext} from "./Game";
import {NpcData} from "../data/NpcData";

export interface DialogInterface {
    talk: (npc: NpcData, onFinish: () => void) => void;
}

const Dialog = forwardRef<DialogInterface>((props, ref) => {

    const {avatars} = useContext(GameContext)!;

    const dialogFrameImage = avatars.dialogFrame;
    const dialogAspectRatio = dialogFrameImage.width / dialogFrameImage.height;

    const typingTextInterface = useRef<TypingTextInterface>(null);

    const dialogIndex = useRef<number>(-1);
    const dialog = useRef<Map<number, string[]>>(new Map<number, string[]>());
    const onFinishRef = useRef<() => void>(() => {
    });

    const [displayDialog, setDisplayDialog] = useState<boolean>(false);
    const [displayButton, setDisplayButton] = useState<boolean>(false);

    const onFinishTyping = () => {
        setDisplayButton(true);
    }

    useImperativeHandle(ref, () => ({
        talk: (npc: NpcData, onFinish: () => void) => {
            onFinishRef.current = onFinish
            setDisplayDialog(true);
            dialogIndex.current = 0;
            dialog.current = npc.dialog;
            const lines = dialog.current.get(dialogIndex.current)!;
            typingTextInterface.current!.type(lines, onFinishTyping);
        }
    }));

    const button = () => {
        const nextDialogIndex = dialogIndex.current + 1
        const hasNext = nextDialogIndex < dialog.current.size;
        const image = hasNext ? avatars.dialogNext : avatars.dialogClose;
        const clear = () => {
            typingTextInterface.current!.clear();
            setDisplayButton(false);
        }
        const onClick = hasNext ?
            () => {
                clear();
                dialogIndex.current = nextDialogIndex;
                const lines = dialog.current.get(nextDialogIndex)!;
                typingTextInterface.current!.type(lines, onFinishTyping);
            } :
            () => {
                clear();
                setDisplayDialog(false);
                onFinishRef.current();
                dialogIndex.current = 0;
                dialog.current = new Map<number, string[]>();
                onFinishRef.current = () => {
                };
            };
        return <div style={{backgroundImage: "url(" + image.src + ")"}} className={"FadeInOutButton"} onClick={onClick}/>
    }

    const dialogFrameStyle = () => {
        return {
            aspectRatio: dialogAspectRatio,
            backgroundImage: "url(" + dialogFrameImage.src + ")"
        };
    }

    const dialogTextStyle = () => {
        return {
            aspectRatio: dialogAspectRatio
        };
    }

    return (
        <div className={"Layout Dialog"} style={{display: displayDialog ? "flex" : "none"}}>
            <div style={dialogFrameStyle()} className={"DialogFrame"}>
                <div style={dialogTextStyle()} className={"DialogText"}>
                    <TypingText ref={typingTextInterface}/>
                </div>
                {displayButton && button()}
            </div>
        </div>
    );

});

export default Dialog;