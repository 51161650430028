import {Position} from "./Position";
import {Direction} from "./Direction";
import {ItemResponse} from "../api/ItemResponse";

export class ItemData {

    private readonly _id: string;
    private readonly _position: Position;
    private readonly _direction: Direction;

    public constructor(id: string, position: Position, direction: Direction) {
        this._id = id;
        this._position = position;
        this._direction = direction;
    }

    public static fromResponse(itemResponse: ItemResponse): ItemData {
        return new ItemData(
            itemResponse.id,
            Position.fromResponse(itemResponse.position),
            Direction.fromKey(itemResponse.direction)
        );
    }

    get id(): string {
        return this._id;
    }

    get position(): Position {
        return this._position;
    }

    get direction(): Direction {
        return this._direction;
    }

    getEngagingPosition(): Position {
        const engagingCol = this.position.col + this.direction.colDelta;
        const engagingRow = this.position.row + this.direction.rowDelta;
        return new Position(engagingCol, engagingRow);
    }

    getEngagingDirection(): Direction {
        return this.getEngagingPosition().getDirectionToTarget(this.position);
    }

}