export class Direction {

    public static TOP: Direction = new Direction("top", 1, -1);
    public static BOTTOM: Direction = new Direction("bottom", -1, 1);
    public static LEFT: Direction = new Direction("left", -1, -1);
    public static RIGHT: Direction = new Direction("right", 1, 1);
    public static TOP_LEFT: Direction = new Direction("top-left", 0, -1);
    public static TOP_RIGHT: Direction = new Direction("top-right", 1, 0);
    public static BOTTOM_LEFT: Direction = new Direction("bottom-left", -1, 0);
    public static BOTTOM_RIGHT: Direction = new Direction("bottom-right", 0, 1);

    public static DIRECTIONS = [
        Direction.TOP,
        Direction.BOTTOM,
        Direction.LEFT,
        Direction.RIGHT,
        Direction.TOP_LEFT,
        Direction.TOP_RIGHT,
        Direction.BOTTOM_LEFT,
        Direction.BOTTOM_RIGHT
    ];

    private readonly _key: string;
    private readonly _colDelta: number;
    private readonly _rowDelta: number;

    constructor(key: string, colDelta: number, rowDelta: number) {
        this._key = key;
        this._colDelta = colDelta;
        this._rowDelta = rowDelta;
    }

    public static fromKey(key: string): Direction {
        const direction = this.DIRECTIONS.find(d => d.key === key);
        if (direction) return direction;
        throw new Error("Direction not found for key " + key);
    }

    public static fromDeltas(colDelta: number, rowDelta: number): Direction {
        const direction = this.DIRECTIONS.find(d => d.colDelta === colDelta && d.rowDelta === rowDelta);
        if (direction) return direction;
        throw new Error("Direction not found for colDelta " + colDelta + " and rowDelta " + rowDelta);
    }

    get key(): string {
        return this._key;
    }

    get colDelta(): number {
        return this._colDelta;
    }

    get rowDelta(): number {
        return this._rowDelta;
    }

}