import {ItemData} from "./ItemData";
import {Position} from "./Position";
import {Direction} from "./Direction";
import {NpcResponse} from "../api/NpcResponse";

export class NpcData extends ItemData {

    private readonly _dialog: Map<number, string[]>;
    private readonly _itemId: string | undefined;

    constructor(
        id: string,
        position: Position,
        direction: Direction,
        dialog: Map<number, string[]>,
        itemId: string | undefined
    ) {
        super(id, position, direction);
        this._dialog = dialog;
        this._itemId = itemId;
    }

    public static fromResponse(npcResponse: NpcResponse): NpcData {
        const dialog = new Map<number, string[]>();
        for (const key in npcResponse.dialog) {
            dialog.set(Number(key), npcResponse.dialog[key]);
        }
        return new NpcData(
            npcResponse.id,
            Position.fromResponse(npcResponse.position),
            Direction.fromKey(npcResponse.direction),
            dialog,
            npcResponse.itemId
        );
    }

    get itemId(): string | undefined {
        return this._itemId;
    }

    get dialog(): Map<number, string[]> {
        return this._dialog;
    }

}