import React, {createContext, useEffect, useState} from "react";
import Fetcher from "../api/Fetcher";
import {GameResponse} from "../api/GameResponse";
import {Sprite} from "../data/Sprite";
import {Avatars} from "../data/Avatars";
import {SpriterAvatarResponse} from "../api/AvatarResponse";

interface GameContextType {
    avatars: Avatars,
    sprite: Sprite
}

export const GameContext = createContext<GameContextType | undefined>(undefined);

const Game = (props: any) => {

    console.log("GAME");

    const [game, setGame] = useState<GameResponse | undefined>();
    const [heroes, setHeroes] = useState<SpriterAvatarResponse[] | undefined>();
    const [avatars, setAvatars] = useState<Avatars | undefined>();

    useEffect(() => {
        new Fetcher<GameResponse>("/data/game.json")
            .onSuccess((gameData: GameResponse) => setGame(gameData))
            .fetch();
        new Fetcher<SpriterAvatarResponse[]>("/data/heroes.json")
            .onSuccess((heroes: SpriterAvatarResponse[]) => setHeroes(heroes))
            .fetch();
    }, []);

    useEffect(() => {
        if (!game || !heroes) {
            return;
        }
        Avatars.fromResponse(game, heroes).then((avatars) => setAvatars(avatars));
    }, [game, heroes]);

    if (!game || !heroes || !avatars) {
        return;
    }

    const gameContext = {
        avatars: avatars,
        sprite: Sprite.fromResponse(game.sprite)
    }

    return (
        <GameContext.Provider value={gameContext}>
            {props.children}
        </GameContext.Provider>
    );

}

export default Game;