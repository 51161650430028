import React, {useContext, useRef} from "react";
import {LevelContext} from "./Level";
import Step from "./Step";
import Npc from "./Npc";
import Hero, {HeroInterface} from "./Hero";
import Door from "./Door";
import Item from "./Item";
import {SessionContext} from "./Session";
import {Position} from "../data/Position";
import {Direction} from "../data/Direction";

const Board = () => {

    console.log("BOARD");

    const {hero, itemIds} = useContext(SessionContext)!;

    const {
        npcs,
        items,
        doors,
        steps,
    } = useContext(LevelContext)!;


    const heroInterface = useRef<HeroInterface>(null);
    const onWalkFinishedRef = useRef<() => void>(() => {
    });

    const locked = useRef<boolean>(false);

    const unlock = () => {
        locked.current = false;
    }

    const acquireLock = () => {
        if (locked.current) return false;
        locked.current = true;
        return true;
    }

    const walkHero = (position: Position, direction: Direction, onWalkFinished: () => void) => {
        onWalkFinishedRef.current = onWalkFinished;
        heroInterface.current!.walk(position, direction);
    }

    const renderSteps = () => {
        return steps.map(((position, index) => {
            return <Step key={index}
                         position={position}
                         acquireLock={acquireLock}
                         walkHero={walkHero}/>
        }));
    }

    const renderNpcs = () => {
        return npcs.map(((npc, index) => {
            return <Npc key={index}
                        npc={npc}
                        acquireLock={acquireLock}
                        walkHero={walkHero}/>
        }));
    }

    const renderItems = () => {
        return items
            .filter(item => !itemIds.includes(item.id))
            .map(((item, index) => {
                return <Item key={index}
                             item={item}
                             acquireLock={acquireLock}
                             walkHero={walkHero}/>
            }));
    }

    const renderDoors = () => {
        return doors.map(((door, index) => {
            return <Door key={index}
                         door={door}
                         acquireLock={acquireLock}
                         walkHero={walkHero}/>
        }));
    }

    const renderHero = () => {
        const onWalkFinished = () => {
            onWalkFinishedRef.current();
            unlock();
        }
        return <Hero id={hero.id}
                     ref={heroInterface}
                     position={hero.position}
                     direction={hero.direction}
                     onWalkFinished={onWalkFinished}/>
    }

    return (
        <React.Fragment>
            {renderSteps()}
            {renderDoors()}
            {renderNpcs()}
            {renderItems()}
            {renderHero()}
        </React.Fragment>
    )

}

export default Board;