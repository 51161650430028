import React, {useContext} from "react";
import {SessionContext} from "./Session";
import {CanvasContext} from "./Canvas";
import {Direction} from "../data/Direction";
import {Position} from "../data/Position";
import {NpcData} from "../data/NpcData";
import Spriter from "./Spriter";

interface NpcProps {
    npc: NpcData,
    acquireLock: () => boolean,
    walkHero: (position: Position, direction: Direction, onWalkFinished: () => void) => void
}

const Npc = (props: NpcProps) => {

    const {startDialog} = useContext(CanvasContext)!;
    const {itemIds, addItem} = useContext(SessionContext)!;

    const {
        npc,
        acquireLock,
        walkHero,
    } = props;

    const onWalkFinish = () => {
        let onFinish = () => {
        }
        if (npc.itemId && !itemIds.find(itemId => itemId === npc.itemId)) {
            onFinish = () => addItem(npc.itemId!);
        }
        startDialog(npc, onFinish);
    }

    const onClickHandler = () => {
        if (!acquireLock()) return;
        walkHero(npc.getEngagingPosition(), npc.getEngagingDirection(), onWalkFinish);
    }

    return <Spriter id={npc.id}
                    position={npc.position}
                    direction={npc.direction}
                    onClickHandler={onClickHandler}/>

}

export default Npc;