import {PositionResponse} from "../api/PositionResponse";
import {Direction} from "./Direction";

export class Position {

    private readonly _col: number;
    private readonly _row: number;

    constructor(col: number, row: number) {
        this._col = col;
        this._row = row;
    }

    public static fromResponse(positionResponse: PositionResponse): Position {
        return new Position(positionResponse.col, positionResponse.row);
    }

    get col(): number {
        return this._col;
    }

    get row(): number {
        return this._row;
    }

    equals(position: Position) {
        return this.col === position.col && this.row === position.row;
    }

    getDirectionToTarget(target: Position): Direction {
        const colDelta = Math.sign(target.col - this.col);
        const rowDelta = Math.sign(target.row - this.row);
        return Direction.fromDeltas(colDelta, rowDelta)
    }

    getKey = (): string => {
        return this.col + "-" + this.row;
    }

    getManhattanDistance = (target: Position): number => {
        return Math.abs(this.col - target.col) + Math.abs(this.row - target.row);
    }

}