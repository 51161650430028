import React, {createContext, useContext, useEffect, useState} from "react";
import Fetcher from "../api/Fetcher";
import {SessionResponse} from "../api/SessionResponse";
import {ItemData} from "../data/ItemData";
import {Position} from "../data/Position";
import {Direction} from "../data/Direction";
import {CanvasContext} from "./Canvas";

interface SessionContextType {
    levelId: string,
    hero: ItemData,
    itemIds: string[]
    addItem: (itemId: string) => void,
    switchLevel: (levelId: string, position: Position, direction: Direction) => void
}

export const SessionContext = createContext<SessionContextType | undefined>(undefined);

const Session = (props: any) => {

    console.log("SESSION")

    const {startLoading} = useContext(CanvasContext)!;

    const [levelId, setLevelId] = useState<string | undefined>();
    const [hero, setHero] = useState<ItemData | undefined>();
    const [itemIds, setItemIds] = useState<string[] | undefined>();

    useEffect(() => {
        new Fetcher<SessionResponse>("/data/session.json")
            .onSuccess((sessionResponse: SessionResponse) => {
                setLevelId(sessionResponse.levelId);
                setHero(ItemData.fromResponse(sessionResponse.hero));
                setItemIds(sessionResponse.itemIds);
            })
            .fetch();
    }, []);

    if (!levelId || !hero || !itemIds) {
        return;
    }

    const addItem = (itemId: string) => {
        setItemIds(itemIds.concat(itemId));
    }

    const switchLevel = (levelId: string, position: Position, direction: Direction): void => {
        startLoading();
        setLevelId(levelId);
        setHero(new ItemData(hero.id, position, direction));
    }

    const sessionContext = {
        levelId: levelId,
        hero: hero,
        itemIds: itemIds,
        addItem: addItem,
        switchLevel: switchLevel
    }

    return (
        <SessionContext.Provider value={sessionContext}>
            {props.children}
        </SessionContext.Provider>
    )

}

export default Session;