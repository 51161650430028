import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./Home";
import "./styles/main.scss"
import Launcher from "./components/Launcher";

const App = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/screen" element={<Launcher/>}/>
                <Route path="/sandbox" element={<Launcher sandbox/>}/>
            </Routes>
        </BrowserRouter>
    )

};

export default App;