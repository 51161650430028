import React, {forwardRef, useImperativeHandle, useState} from "react";

export interface TypingTextInterface {
    type: (lines: string[], onFinishTyping: () => void) => void;
    clear: () => void;
}

const TypingText = forwardRef<TypingTextInterface>((props, ref) => {

    const intervalWait = 40;
    const [typedLines, setTypedLines] = useState<string[]>([]);
    const [typingText, setTypingText] = useState<string>("");

    useImperativeHandle(ref, () => ({
        type: (lines: string[], onFinishTyping: () => void) => {
            let lineIndex = 0;
            let letterIndex = 0;
            const intervalId = setInterval(() => {
                const line = lines[lineIndex];
                setTypingText(line.substring(0, letterIndex + 1));
                if (letterIndex === line.length) {
                    if (lineIndex + 1 === lines.length) {
                        onFinishTyping();
                        clearInterval(intervalId);
                        return;
                    }
                    setTypedLines((prevTypedLines) => [...prevTypedLines, line]);
                    setTypingText("");
                    letterIndex = 0;
                    lineIndex++;
                }
                letterIndex++;
            }, intervalWait);
        },
        clear: () => {
            setTypedLines([]);
            setTypingText("");
        }
    }));

    return (
        <React.Fragment>
            {typedLines.map((typedLines, index) => <p key={index}>{typedLines}</p>)}
            <p>{typingText}</p>
        </React.Fragment>
    )

});

export default TypingText;