import React from "react";
import {useNavigate} from "react-router-dom";

const Home = () => {

    const navigate = useNavigate();

    return (
        <div>
            <button onClick={() => navigate("/screen")}>Screen</button>
            <button onClick={() => navigate("/sandbox")}>Sandbox</button>
        </div>
    )

}

export default Home;