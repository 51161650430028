class Fetcher<T> {

    private readonly _basePath: string;
    private readonly _path: string;
    private _options: any;
    private _successHandler: Function;
    private _errorHandler: Function;
    private _notFoundHandler: Function;

    constructor(path: string) {
        this._basePath = window.location.origin
        this._path = path;
        this._options = {};
        this._successHandler = (data: T) => console.error(data);
        this._errorHandler = (error: Error) => console.error(error.message);
        this._notFoundHandler = () => console.error('Resource not found');
    }

    withPostBody(postBody: any): this {
        this._options = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(postBody)
        }
        return this;
    }

    withPostData(postData: any): this {
        this._options = {
            method: "POST",
            body: postData
        }
        return this;
    }


    // Method to set a custom success handler
    onSuccess(callback: (data: T) => void): this {
        this._successHandler = callback;
        return this;
    }

    onError(callback: Function): this {
        this._errorHandler = callback;
        return this;
    }

    fetch() {
        fetch(this._basePath + this._path, this._options)
            .then((response) => {
                if (response.status === 404) {
                    this._notFoundHandler();
                    return;
                }
                if (!response.ok) {
                    throw new Error("Unexpected response code " + response.status);
                }
                return response.json();
            })
            .then(json => {
                //TODO remove intended delay
                setTimeout(() => this._successHandler(json), 500);
            })
            .catch(error => this._errorHandler(error))
    }


}

export default Fetcher;