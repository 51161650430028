import React, {useContext} from "react";
import {GameContext} from "./Game";
import {LevelContext} from "./Level";
import {Position} from "../data/Position";

interface StaticProps {
    id: string,
    position: Position,
    onClickHandler?: () => void
}

const Static = (props: StaticProps) => {

    const {avatars} = useContext(GameContext)!;
    const {tileWidth, getItemLeft, getItemTop} = useContext(LevelContext)!;

    const {
        id,
        position,
        onClickHandler = () => {
        }
    } = props;

    const avatar = avatars.getStaticAvatar(id);
    const image = avatar.image;
    const size = tileWidth * avatar.scale;

    return <div className={"Static"} onClick={onClickHandler} style={{
        width: size,
        height: size,
        backgroundImage: "url(" + image.src + ")",
        left: getItemLeft(position, size),
        top: getItemTop(position, size)
    }}/>

}

export default Static;