import {Avatar} from "./Avatar";

export class StaticAvatar extends Avatar {

    private readonly _image: HTMLImageElement;

    constructor(name: string, scale: number, image: HTMLImageElement) {
        super(name, scale)
        this._image = image;
    }

    get image(): HTMLImageElement {
        return this._image;
    }

}