import {ItemData} from "./ItemData";
import {DoorTarget} from "./DoorTarget";
import {Position} from "./Position";
import {Direction} from "./Direction";
import {DoorResponse} from "../api/DoorResponse";

export class DoorData extends ItemData {

    private readonly _target: DoorTarget;
    private readonly _dependencies: string[];
    private readonly _lockedMessage: string[];

    constructor(
        id: string,
        position: Position,
        direction: Direction,
        target: DoorTarget,
        dependencies: string[],
        lockedMessage: string[]
    ) {
        super(id, position, direction);
        this._target = target;
        this._dependencies = dependencies;
        this._lockedMessage = lockedMessage;
    }

    public static fromResponse(doorResponse: DoorResponse): DoorData {
        return new DoorData(
            doorResponse.id,
            Position.fromResponse(doorResponse.position),
            Direction.fromKey(doorResponse.direction),
            DoorTarget.fromResponse(doorResponse.target),
            doorResponse.dependencies,
            doorResponse.lockedMessage
        );
    }

    get target(): DoorTarget {
        return this._target;
    }

    get dependencies(): string[] {
        return this._dependencies;
    }

    get lockedMessage(): string[] {
        return this._lockedMessage;
    }

}