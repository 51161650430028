import React, {forwardRef, useImperativeHandle, useState} from "react";

export interface LoadingInterface {
    start: () => void;
    finish: () => void;
}

const Loading = forwardRef<LoadingInterface>((props, ref) => {

    const [display, setDisplay] = useState<boolean>(true);

    useImperativeHandle(ref, () => ({
        start: () => {
            setDisplay(true);
        },
        finish: () => {
            setDisplay(false);
        }
    }));

    return (
        <div style={{display: display ? "flex" : "none"}} className={"Layout Loading"}>
            <p>Loading...</p>
        </div>
    )

});

export default Loading;