import React, {useContext} from "react";
import {SessionContext} from "./Session";
import {CanvasContext} from "./Canvas";
import {DoorData} from "../data/DoorData";
import {Direction} from "../data/Direction";
import {Position} from "../data/Position";
import Static from "./Static";

interface DoorProps {
    door: DoorData,
    acquireLock: () => boolean,
    walkHero: (position: Position, direction: Direction, onWalkFinished: () => void) => void,
}

const Door = (props: DoorProps) => {

    const {startMessage} = useContext(CanvasContext)!;
    const {itemIds, switchLevel} = useContext(SessionContext)!;

    const {
        door,
        acquireLock,
        walkHero
    } = props;

    const onWalkFinish = () => {
        if (door.dependencies.some(dependency => !itemIds.find(itemId => itemId === dependency))) {
            startMessage(door.lockedMessage);
        } else {
            switchLevel(door.target.levelId, door.target.position, door.target.direction);
        }
    }

    const onClickHandler = () => {
        if (!acquireLock()) return;
        walkHero(door.getEngagingPosition(), door.getEngagingDirection(), onWalkFinish);
    }

    return <Static id={door.id}
                   position={door.position}
                   onClickHandler={onClickHandler}/>

}

export default Door;