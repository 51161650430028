import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import Spriter from "./Spriter";
import {LevelContext} from "./Level";
import {Position} from "../data/Position";
import {Direction} from "../data/Direction";
import {Status} from "../data/Status";

export interface HeroInterface {
    walk: (finalPosition: Position, finalDirection: Direction) => void;
}

interface HeroProps {
    id: string,
    position: Position,
    direction: Direction,
    onWalkFinished: () => void;
}

const Hero = forwardRef<HeroInterface, HeroProps>((props, ref) => {

    console.log("HERO");

    const {steps} = useContext(LevelContext)!;

    const {id, position, direction, onWalkFinished} = props;

    const spriteRef = useRef<HTMLDivElement | null>(null);
    const finalDirectionRef = useRef<Direction>(direction);

    const [path, setPath] = useState<Position[]>([]);
    const [currentPosition, setCurrentPosition] = useState<Position>(position);
    const [currentStatus, setCurrentStatus] = useState<Status>(Status.IDLE);
    const [currentDirection, setCurrentDirection] = useState<Direction>(direction);

    useEffect(() => {
        setCurrentPosition(position)
        setCurrentDirection(direction)
    }, [position, direction]);

    useImperativeHandle(ref, () => ({
        walk: (finalPosition: Position, finalDirection: Direction): void => {
            if (currentPosition.equals(finalPosition)) {
                setCurrentDirection(finalDirection)
                onWalkFinished();
                return;
            }
            finalDirectionRef.current = finalDirection
            const path = pathTo(finalPosition);
            setCurrentStatus(Status.WALKING);
            setPath(path);
            return;
        }
    }));

    useEffect(() => {
        if (path.length === 0) return;

        const position = path[0];
        const direction = currentPosition.getDirectionToTarget(position);

        setCurrentPosition(position);
        setCurrentDirection(direction);

        const onTransitioned = () => {
            if (path.length === 1) {
                setCurrentStatus(Status.IDLE);
                setCurrentDirection(finalDirectionRef.current);
                setCurrentPosition(path[0]);
                onWalkFinished();
            }
            setPath(path.slice(1));
        }
        spriteRef.current!.addEventListener('transitionend', onTransitioned, {once: true});
    }, [path]);

    //PATH FINDING

    const pathTo = (target: Position): Position[] => {
        const path = aStar(target);
        return simplifyPath(path!);
    }

    const aStar = (goal: Position): Position[] | null => {

        const openSet = new Set<Position>();
        const cameFrom = new Map<string, Position>();
        const gScore = new Map<string, number>();
        const fScore = new Map<string, number>();

        const startKey = currentPosition.getKey();
        gScore.set(startKey, 0);
        fScore.set(startKey, currentPosition.getManhattanDistance(goal));

        openSet.add(currentPosition);

        while (openSet.size > 0) {

            let current: Position | undefined;
            let currentKey: string | undefined = undefined;

            openSet.forEach(pos => {
                const key = pos.getKey();
                if (!current || (fScore.get(key) ?? Infinity) < (fScore.get(currentKey ?? "") ?? Infinity)) {
                    current = pos;
                    currentKey = key;
                }
            });

            if (!current)  throw new Error("Error while retrieving the path");

            if (current.equals(goal)) {
                return reconstructPath(cameFrom, current);
            }

            openSet.delete(current);

            for (const direction of Direction.DIRECTIONS) {

                const neighbor = new Position(current.col + direction.colDelta, current.row + direction.rowDelta);

                if (!isValidPosition(neighbor)) continue;

                if (direction.colDelta !== 0 && direction.rowDelta !== 0 && !isDiagonalNotCuttingCorner(current, direction)) continue;

                const tentative_gScore = (gScore.get(current.getKey()) ?? Infinity) + 1;

                const neighborKey = neighbor.getKey();
                if (tentative_gScore < (gScore.get(neighborKey) ?? Infinity)) {
                    cameFrom.set(neighborKey, current);
                    gScore.set(neighborKey, tentative_gScore);
                    fScore.set(neighborKey, tentative_gScore + neighbor.getManhattanDistance(goal));
                    openSet.add(neighbor);
                }
            }
        }

        return null;
    }

    const reconstructPath = (cameFrom: Map<string, Position>, current: Position): Position[] => {
        const totalPath = [current];
        while (cameFrom.has(current.getKey())) {
            const step = cameFrom.get(current.getKey());
            totalPath.unshift(step!);
            current = step!;
        }
        return totalPath;
    }

    const simplifyPath = (path: Position[]): Position[] => {
        if (path.length <= 1) return path;

        const simplified = [path[0]];

        for (let i = 1; i < path.length - 1; i++) {
            const prev = simplified[simplified.length - 1];
            const current = path[i];
            const next = path[i + 1];

            const isStraightLine =
                (next.row - current.row) * (current.col - prev.col) ===
                (next.col - current.col) * (current.row - prev.row);
            if (!isStraightLine) simplified.push(current);
        }

        simplified.push(path[path.length - 1]);
        return simplified.slice(1);
    }

    const isDiagonalNotCuttingCorner = (from: Position, directionDeltas: Direction): boolean => {
        const horizontal = new Position(from.col + directionDeltas.colDelta, from.row);
        const vertical = new Position(from.col, from.row + directionDeltas.rowDelta);
        return isValidPosition(horizontal) && isValidPosition(vertical);
    }

    const isValidPosition = (position: Position): boolean => {
        return steps.some(available => available.equals(position));
    }

    return <Spriter id={id}
                    ref={spriteRef}
                    position={currentPosition}
                    status={currentStatus}
                    direction={currentDirection}
                    transitioning={currentStatus === Status.WALKING}/>

});

export default Hero;