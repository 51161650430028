import React from "react";
import Tile from "./Tile";
import {Position} from "../data/Position";
import {Direction} from "../data/Direction";

interface StepProps extends React.PropsWithChildren {
    position: Position,
    acquireLock: () => boolean,
    walkHero: (position: Position, direction: Direction, onWalkFinished: () => void) => void
}

const Step = (props: StepProps) => {

    const {
        position,
        acquireLock,
        walkHero
    } = props;

    const onClickHandler = () => {
        if (!acquireLock()) return;
        walkHero(position, Direction.BOTTOM, () => {
        });
    }

    return <Tile position={position} className={"Step"} onClickHandler={onClickHandler}/>;


}

export default Step;