import React, {useContext} from "react";
import {LevelContext} from "./Level";
import {Position} from "../data/Position";

interface TileProps extends React.PropsWithChildren {
    position: Position,
    className?: string,
    onClickHandler?: () => void
}

const Tile = (props: TileProps) => {

    const {tileWidth, tileHeight, getItemLeft, getItemTop} = useContext(LevelContext)!;

    const {
        position,
        className = "Blank",
        onClickHandler = () => {
        }
    } = props;

    const span = "{" + position.col + "," + position.row + "}";

    return (
        <div className={"Tile"} style={{
            left: getItemLeft(position),
            top: getItemTop(position),
            width: tileWidth,
            height: tileHeight
        }}>
            <div className={className} onClick={onClickHandler}>
                <span>{span}</span>
            </div>
        </div>
    )

}

export default Tile;