import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Canvas from "./Canvas";

interface LauncherProps {
    sandbox?: boolean;
}

const Launcher = (props: LauncherProps) => {

    const navigate = useNavigate();
    const {sandbox = false} = props;

    const [loading, setLoading] = useState<boolean>(true);

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const fullScreenChangeEventKeys = [
        "fullscreenchange",
        "webkitfullscreenchange",
        "mozfullscreenchange",
        "msfullscreenchange"
    ];

    const fullScreenRequestKeys = [
        "requestFullscreen",
        "webkitRequestFullscreen",
        "mozRequestFullScreen",
        "msRequestFullscreen"
    ];

    const closeFullScreenRequestKeys = [
        "exitFullscreen",
        "webkitExitFullscreen",
        "mozCancelFullScreen",
        "msExitFullscreen"
    ];

    useEffect(() => {
        if (sandbox) {
            setLoading(false);
            return;
        }
        fullScreenChangeEventKeys.forEach(fullScreenChangeEvent =>
            document.addEventListener(fullScreenChangeEvent, fullScreenChangeHandler)
        );
        screen.orientation.addEventListener("change", screenOrientationChangeHandler);
        openFullScreen();
        return () => {
            fullScreenChangeEventKeys.forEach(fullScreenChangeEvent =>
                document.removeEventListener(fullScreenChangeEvent, fullScreenChangeHandler)
            );
            screen.orientation.removeEventListener("change", screenOrientationChangeHandler);
        }
    }, []);

    const fullScreenChangeHandler = () => {
        if (document.fullscreenElement) {
            if (isMobile && !screen.orientation.type.includes("landscape")) {
                const screenOrientation = screen.orientation as any;
                screenOrientation["lock"]("landscape").catch(() => setLoading(false));
            } else {
                setLoading(false);
            }
        } else {
            exitLauncher();
        }
    };

    const screenOrientationChangeHandler = () => {
        setLoading(false);
    }

    const openFullScreen = () => {
        const element = document.documentElement as any;
        for (const fullScreenRequestKey of fullScreenRequestKeys) {
            if (fullScreenRequestKey in element) {
                element[fullScreenRequestKey]().catch(() => exitLauncher());
                break;
            }
        }
    }

    const closeFullScreen = () => {
        if (sandbox) {
            exitLauncher();
            return;
        }
        const anyDocument = document as any;
        for (const closeFullScreenRequestKey of closeFullScreenRequestKeys) {
            if (closeFullScreenRequestKey in anyDocument) {
                anyDocument[closeFullScreenRequestKey]().finally(() => exitLauncher());
                break;
            }
        }
    }

    const exitLauncher = () => {
        screen.orientation.unlock();
        navigate("/");
    }

    if (loading) {
        return;
    }

    return <Canvas closeFullScreen={closeFullScreen}/>;

}

export default Launcher;