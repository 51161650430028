import React, {forwardRef, useContext, useEffect, useRef} from "react";
import {LevelContext} from "./Level";
import Frame from "./Frame";
import {GameContext} from "./Game";
import {Position} from "../data/Position";
import {Status} from "../data/Status";
import {Direction} from "../data/Direction";

interface SpriterProps {
    id: string,
    position: Position,
    status?: Status,
    direction: Direction,
    onClickHandler?: () => void,
    transitioning?: boolean,
}

const Spriter = forwardRef<HTMLDivElement, SpriterProps>((props, ref) => {

    const {avatars} = useContext(GameContext)!;
    const {tileWidth, getItemLeft, getItemTop} = useContext(LevelContext)!;

    const {
        id,
        position,
        status = Status.IDLE,
        direction,
        onClickHandler,
        transitioning = false
    } = props;

    const prevPosition = useRef<Position>(position);

    useEffect(() => {
        prevPosition.current = position;
    }, [position]);

    const avatar = avatars.getSpriterAvatar(id);

    const speed = tileWidth * 2;
    const size = tileWidth * avatar.scale;

    const getLeft = (position: Position) => {
        return getItemLeft(position, size);
    }

    const getTop = (position: Position) => {
        return getItemTop(position, size);
    }

    const getTransition = () => {
        if (!transitioning) return "none";
        const deltaX = getLeft(position) - getLeft(prevPosition.current!);
        const deltaY = getTop(position) - getTop(prevPosition.current!);
        const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
        const time = distance / speed;
        return "left " + time + "s linear, top " + time + "s linear";
    }

    return (
        <div className={"Spriter"} onClick={onClickHandler} ref={ref} style={{
            width: size,
            height: size,
            left: getLeft(position),
            top: getTop(position),
            transition: getTransition()
        }}>
            <Frame images={avatar.images}
                   status={status}
                   direction={direction}
                   size={size}/>
        </div>

    )
});

export default Spriter;