export abstract class Avatar {

    private readonly _name: string;
    private readonly _scale: number;

    protected constructor(name: string, scale: number) {
        this._name = name;
        this._scale = scale;
    }

    get name(): string {
        return this._name;
    }

    get scale(): number {
        return this._scale;
    }

}