import React, {createContext, useRef} from "react";
import Game from "./Game";
import Level from "./Level";
import Board from "./Board";
import Inventory from "./Inventory";
import Session from "./Session";
import Loading, {LoadingInterface} from "./Loading";
import Dialog, {DialogInterface} from "./Dialog";
import Message, {MessageInterface} from "./Message";
import {NpcData} from "../data/NpcData";

interface CanvasContextType {
    backgroundWidth: number,
    backgroundHeight: number,
    startLoading: () => void,
    finishLoading: () => void,
    startDialog: (npc: NpcData, onFinish: () => void) => void,
    startMessage: (message: string[]) => void,
    closeFullScreen: () => void
}

export const CanvasContext = createContext<CanvasContextType | undefined>(undefined);

interface CanvasProps {
    closeFullScreen: () => void;
}

const Canvas = (props: CanvasProps) => {

    const loadingInterface = useRef<LoadingInterface>(null);
    const dialogInterface = useRef<DialogInterface>(null);
    const messageInterface = useRef<MessageInterface>(null);

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    let backgroundWidth = screenWidth;
    let backgroundHeight = (screenWidth * 9) / 16;
    if (backgroundHeight > screenHeight) {
        backgroundHeight = screenHeight;
        backgroundWidth = (screenHeight * 16) / 9;
    }

    //INTERFACES

    const startLoading = () => {
        loadingInterface.current!.start();
    }

    const finishLoading = () => {
        loadingInterface.current!.finish();
    }

    const startDialog = (npc: NpcData, onFinish: () => void) => {
        dialogInterface.current!.talk(npc, onFinish);
    }

    const startMessage = (message: string[]) => {
        messageInterface.current!.message(message);
    }

    const canvasContext = {
        backgroundWidth: backgroundWidth,
        backgroundHeight: backgroundHeight,
        startLoading: startLoading,
        finishLoading: finishLoading,
        startDialog: startDialog,
        startMessage: startMessage,
        closeFullScreen: props.closeFullScreen
    }

    return (
        <CanvasContext.Provider value={canvasContext}>
            <div className={"Canvas"}>
                <Loading ref={loadingInterface}/>
                <Game>
                    <Dialog ref={dialogInterface}/>
                    <Message ref={messageInterface}/>
                    <Session>
                        <Level>
                            <Board/>
                        </Level>
                        <Inventory/>
                    </Session>
                </Game>
            </div>
        </CanvasContext.Provider>

    )
}

export default Canvas;