import React, {createContext, useContext, useEffect, useState} from "react";
import Fetcher from "../api/Fetcher";
import {LevelResponse} from "../api/LevelResponse";
import {SessionContext} from "./Session";
import Tile from "./Tile";
import {CanvasContext} from "./Canvas";
import {Position} from "../data/Position";
import {NpcData} from "../data/NpcData";
import {ItemData} from "../data/ItemData";
import {DoorData} from "../data/DoorData";

interface LevelContextType {
    npcs: NpcData[],
    items: ItemData[],
    doors: DoorData[],
    steps: Position[],
    tileWidth: number,
    tileHeight: number,
    getItemLeft: (position: Position, size?: number) => number,
    getItemTop: (position: Position, size?: number) => number
}

export const LevelContext = createContext<LevelContextType | undefined>(undefined);

const Level = (props: any) => {

    console.log("LEVEL")

    const {backgroundWidth, backgroundHeight, finishLoading} = useContext(CanvasContext)!;
    const {levelId, hero} = useContext(SessionContext)!;

    const [levelData, setLevelData] = useState<LevelResponse | undefined>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        new Fetcher<LevelResponse>("/data/levels/" + levelId + ".json")
            .onSuccess((levelData: LevelResponse) => {
                setLevelData(levelData);
                setLoading(false);
                finishLoading();
            })
            .fetch();
    }, [levelId]);

    if (loading || !levelData) {
        return;
    }

    const npcs = levelData.npcs.map(npc => NpcData.fromResponse(npc));
    const items = levelData.items.map(item => ItemData.fromResponse(item));
    const doors = levelData.doors.map(door => DoorData.fromResponse(door));
    const steps = levelData.steps.map(step => Position.fromResponse(step));

    const heightFromWidth = (tileWidth: number) => {
        const angleInRadians = (30 * Math.PI) / 180;
        return tileWidth * Math.tan(angleInRadians);
    }

    const isometricColumns = levelData.isometricColumns;
    const tileWidth = backgroundWidth / isometricColumns;
    const tileHeight = heightFromWidth(tileWidth);
    const isometricRows = Math.floor(backgroundHeight / (tileHeight / 2));

    const getTileLeft = (position: Position) => {
        return ((position.row + position.col) / 2) * tileWidth
    }

    const getTileTop = (position: Position) => {
        return ((position.row - position.col) / 2) * tileHeight;
    }

    const getItemLeft = (position: Position, size?: number) => {
        let leftOffset = 0;
        if (size) {
            leftOffset = (tileWidth - size) / 2;
        }
        return getTileLeft(position) + leftOffset;
    }

    const getItemTop = (position: Position, size?: number) => {
        let topOffset = 0;
        if (size) {
            //for center aligned ->  topOffset = (tileHeight - size) / 2;
            topOffset = (tileHeight / 2) - size;
        }
        return getTileTop(position) + topOffset;
    }

    //build blanks
    const blanks: Position[] = [];
    for (let col = -isometricColumns; col < isometricColumns; col++) {
        for (let row = 0; row < isometricRows; row++) {
            const position = new Position(col, row);
            if (steps.some(step => step.equals(position))) {
                continue;
            }
            if (hero.position.equals(position)) {
                continue;
            }
            const tileLeftPos = Math.floor(getTileLeft(position));
            const tileTopPos = Math.floor(getTileTop(position));
            if (tileLeftPos < 0 || tileTopPos < 0) {
                continue;
            }
            if (tileLeftPos + tileWidth > backgroundWidth) {
                continue;
            }
            if (tileTopPos + tileHeight > backgroundHeight) {
                continue;
            }
            blanks.push(position);
        }
    }

    const renderBlanks = () => {
        return blanks.map(((position, index) => {
            return <Tile key={index} position={position}/>
        }));
    }

    const levelContext = {
        npcs: npcs,
        items: items,
        doors: doors,
        steps: steps,
        tileWidth: tileWidth,
        tileHeight: tileHeight,
        getItemLeft: getItemLeft,
        getItemTop: getItemTop
    }

    const levelStyle = {
        width: backgroundWidth,
        height: backgroundHeight
    }

    return (
        <LevelContext.Provider value={levelContext}>
            <div className={"Level"} style={levelStyle}>
                {renderBlanks()}
                {props.children}
            </div>
        </LevelContext.Provider>
    )

}

export default Level;