import {Position} from "./Position";
import {Direction} from "./Direction";
import {DoorTargetResponse} from "../api/DoorResponse";

export class DoorTarget {

    private readonly _levelId: string;
    private readonly _position: Position;
    private readonly _direction: Direction;

    constructor(levelId: string, position: Position, direction: Direction) {
        this._levelId = levelId;
        this._position = position;
        this._direction = direction;
    }

    public static fromResponse(doorTargetResponse: DoorTargetResponse): DoorTarget {
        return new DoorTarget(
            doorTargetResponse.levelId,
            Position.fromResponse(doorTargetResponse.position),
            Direction.fromKey(doorTargetResponse.direction)
        );
    }

    get levelId(): string {
        return this._levelId;
    }

    get position(): Position {
        return this._position;
    }

    get direction(): Direction {
        return this._direction;
    }

}